<template>
  <el-scrollbar>
    <HeaderRow ref="headerRowRef">
      <template #button>
        <div>
          <el-button
            type="primary"
            icon="el-icon-plus"
            class="add-btn"
            @click="handleAddApps"
          >
            新增应用
          </el-button>
        </div>
      </template>
    </HeaderRow>
    <el-row>
      <!-- 工具栏S -->
      <div class="tooltip-wrap" ref="toolbarRef">
        <el-form
          :inline="true"
          :model="tableFormLine"
          ref="toolbarFormRefs"
          class="demo-form-inline"
        >
          <el-form-item label="应用名称">
            <el-input
              v-model="tableFormLine.appName"
              size="medium"
              placeholder="请输入应用名称"
              class="app-name"
            ></el-input>
          </el-form-item>
          <el-form-item label="管理员">
            <el-input
              v-model="tableFormLine.appAdmin"
              size="medium"
              placeholder="请输入管理员"
              class="app-admin"
            ></el-input>
          </el-form-item>
          <el-form-item label="负责部门">
            <el-input
              v-model="tableFormLine.appClass"
              size="medium"
              placeholder="请输入部门"
              class="app-class"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              class="app-category"
              v-model="tableFormLine.category"
              placeholder="类别"
            >
              <el-option
                v-for="item in toolCategoryOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="app-btn-group">
            <!-- <el-button type="primary" @click="onSubmit">查询</el-button> -->
            <el-button-group>
              <el-button
                type="primary"
                class="tooltip-btn search"
                @click="onSubmitSearch"
                >查询</el-button
              >
              <el-button
                type="default"
                class="tooltip-btn reset"
                @click="onSubmitReset"
                >重置</el-button
              >
            </el-button-group>
          </el-form-item>
        </el-form>
      </div>
      <!-- 工具栏E -->
    </el-row>
    <!-- tabl内容S -->
    <el-table
      v-loading="tableLoading"
      class="app-table"
      :data="appList"
      highlight-current-row
      style="width: 100%;"
      :height="tableHeight"
    >
      <!-- <el-table-column
        prop="id"
        label="id"
        show-overflow-tooltip
        width="80"
      ></el-table-column> -->
      <el-table-column
        prop="name"
        width="100"
        label="应用名称"
        show-overflow-tooltip
        fixed
      >
        <template #default="scope">
          <div class="colum-name">
            <!-- <img src="../../../assets/emailIcon.png" alt="" /> -->
            <span>{{ scope.row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="page"
        label="默认地址"
        show-overflow-tooltip
        width="160"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="应用图标"
        show-overflow-tooltip
        width="120"
      >
        <template #default="scope">
          <el-image
            style="width:48px;height:48px;"
            :src="scope.row.icon"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="description"
        label="描述"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="status" label="状态" width="80">
        <template #default="scope">
          <span>{{ fstatus(scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="base.created"
        show-overflow-tooltip
        label="创建时间"
      ></el-table-column>
      <el-table-column
        prop="base.updaterName"
        show-overflow-tooltip
        label="更新人"
      ></el-table-column>
      <el-table-column
        prop="base.creatorName"
        show-overflow-tooltip
        label="管理员"
      ></el-table-column>

      <el-table-column
        prop="base.updated"
        show-overflow-tooltip
        label="更新时间"
      ></el-table-column>
      <el-table-column prop="base.updaterName" min-width="250" label="操作">
        <template #default="scope">
          <!-- 按钮操作S -->
          <el-button
            type="primary"
            icon="el-icon-edit"
            class="app-edit-btn"
            @click="handleClickEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-delete"
            class="app-del-btn"
            @click="handleClickDel(scope.row)"
            >删除</el-button
          >

          <!-- 按钮操作E -->
          <el-popover placement="top-start" :width="400" trigger="hover">
            <el-row>
              <el-tag type="info">应用名称: </el-tag
              ><span class="tooltip">{{ scope.row.name }}</span>
            </el-row>
            <el-row>
              <el-tag type="info">默认地址: </el-tag
              ><span class="tooltip">{{ scope.row.page }}</span>
            </el-row>
            <el-row>
              <el-tag type="info">描述: </el-tag
              ><span class="tooltip">{{ scope.row.description || "无" }}</span>
            </el-row>
            <el-row>
              <el-tag type="info">Appkey: </el-tag
              ><span class="tooltip">{{ scope.row.appkey }}</span>
            </el-row>
            <el-row>
              <el-tag type="info">Secret: </el-tag
              ><span class="tooltip">{{ scope.row.secret }}</span>
            </el-row>
            <el-row>
              <el-tag type="info">应用状态: </el-tag
              ><span>{{ fstatus(scope.row.status) }}</span>
            </el-row>
            <template #reference>
              <el-button
                icon="el-icon-view"
                class="app-view"
                type="text"
                circle
              >
              </el-button>
            </template>
          </el-popover>
          <el-tooltip
            v-if="scope.row.status == 0"
            content="通过"
            :visible-arrow="false"
            effect="light"
            placement="top-start"
            :offset="-10"
          >
            <el-button
              icon="el-icon-s-check"
              type="text"
              class="button"
              @click="process('approval', scope.row)"
            >
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-if="scope.row.status == 1"
            content="下线"
            :visible-arrow="false"
            effect="light"
            placement="top-start"
            :offset="-10"
          >
            <el-button
              icon="el-icon-scissors"
              type="text"
              class="button"
              @click="process('offline', scope.row)"
            >
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-if="scope.row.status == 2"
            content="上线"
            :visible-arrow="false"
            effect="light"
            placement="top-start"
            :offset="-10"
          >
            <el-button
              icon="el-icon-upload"
              type="text"
              class="button"
              @click="process('online', scope.row)"
            >
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- 暂无数据插槽 -->
      <template v-slot:empty>
        <div class="table-nodata">
          <img src="../../../assets/noDataBg.png" alt="" srcset="" />
          <a
            href="javascript:viod(0)"
            class="btn-add"
            @click="handleAddApps"
          >
            新增应用
          </a>
        </div>
      </template>
    </el-table>
    <!-- tabl内容E -->

    <!-- 可删除 -->
    <AdminModal
      :showModal="showDelModal"
      btnType="3"
      sureText="确认删除"
      cnacel="取消"
      @cancel="showDelModal = false"
      @submit="delCurrentApps"
    >
      <template v-slot:body>
        <div class="body-wrap">
          <i class="el-icon-question"></i>
          <p>确定要删除此应用？</p>
          <span>应用删除后不可恢复</span>
        </div>
      </template>
    </AdminModal>
    <!-- 不可删除 -->
    <AdminModal
      :showModal="showDelModalDisable"
      btnType="1"
      sureText="我知道了"
      @cancel="showDelModalDisable = false"
      @submit="showDelModalDisable = false"
    >
      <template v-slot:body>
        <div class="body-wrap">
          <i class="el-icon-warning"></i>
          <p>该配置类别正在使用中，不可删除！</p>
        </div>
      </template>
    </AdminModal>
  </el-scrollbar>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  toRefs,
  onMounted,
  onUnmounted,
  onBeforeMount,
} from "vue";
import router from "@/router/index.js";
import {
  appSearch,
  appApproval,
  appEnable,
  appDisable,
  setAllByType,
} from "@/utils/api";
import { setTypes } from "@/utils/constant";
import { appStatus, role } from "@/utils/constant";
import AdminModal from "@/views/Admin/components/AdminModal";
import { ElMessageBox, ElMessage } from "element-plus";
import HeaderRow from "@/views/Admin/components/HeaderRow";
export default {
  name: "Apps",
  components: {
    AdminModal, HeaderRow
  },
  setup: function() {
    const toolbarFormRefs = ref(null);
    const toolbarRef = ref(null);
    const state = reactive({
      // 模板数据
      tempSource: [
        {
          appkey: "",
          base: {
            created: "2020-01-01 10:00:00",
            creatorName: "张无忌",
            creatorNo: 12345667,
            updated: "2020-01-01 10:00:00",
            updaterName: "Admin",
            updaterNo: 12345667,
          },
          description:
            "这是应用描述文字这是应用描述文字这是应用描述文字这是应用描述文字这是应用描述文字这是应用描述文字这是应用描述文字这是应用描述文字",
          icon: "http://a.com/icon?id=xxxxxxx",
          level: 1,
          name: "用户中心",
          page: "http://a.com/ticket/",
          secret: "qweidi",
          status: 1,
        },
      ],
      // 应用列表
      appList: [],
      // 工具栏表单
      tableFormLine: {
        appClass: "",
        appName: "",
        appAdmin: "",
        category: "",
      },
      toolCategoryOptions: [], // 工具栏表单select下拉框
      showDelModal: false, // 模态框--当前应用可删除
      showDelModalDisable: false, // 模态框--当前应用不可删除
      tableLoading: false, // table加载loading
      tableHeight: 0, // table高度
      currentRowData: "", // 当前行数据
      // 新增应用
      handleAddApps: () => {
        router.push({
          path: "/admin/apps/add",
        });
      },
      // table编辑按钮
      handleClickEdit: (row) => {
        console.log(row);
        const { appkey } = row;
        router.push({
          path: `/admin/apps/edit/${appkey}`,
        });
      },
      // table删除按钮
      handleClickDel: (row) => {
        // 根据应用当前状态提示
        state.currentRowData = row;
        if (row.status === 0) {
          state.showDelModal = true;
        }
        if (row.status === 1) {
          state.showDelModalDisable = true;
        }
      },
      // modal点击删除
      delCurrentApps: async () => {
        console.log(JSON.parse(JSON.stringify(state.currentRowData)));
        try {
          const res = await appDisable(state.currentRowData.appkey);
          if (res.code === 0 && res.success) {
            state.showDelModal = false;
            state.findMyList();
            ElMessage.success({
              type: "info",
              message: `删除成功`,
            });
          }
        } catch (error) {
          console.log(error);
        }
      },
      isEmpty(object) {
        let status = false;
        Object.keys(object).forEach((v) => {
          if (
            object[v] !== null &&
            object[v] !== "" &&
            object[v] !== "undefined"
          ) {
            status = true;
          }
        });
        return status ? true : false;
      },
      // 工具栏查询按钮事件
      onSubmitSearch: () => {
        let {
          appList,
          tableFormLine,
          tableFormLine: { appClass, appName, appAdmin, category },
          isEmpty,
        } = state;
        let isPrintParams = isEmpty(tableFormLine);
        if (isPrintParams) {
          const copyObj = JSON.parse(JSON.stringify(appList));
          const tempList = copyObj.filter((item) => {
            return (
              item.name.includes(appName) &&
              item.base.creatorName.includes(appAdmin) &&
              item.name.includes(appClass) &&
              item.type == category
            );
          });
          console.log(JSON.parse(JSON.stringify(appList)), tempList);
          state.appList = tempList;
          return false;
        }
        ElMessage.warning({
          type: "warning",
          message: `请输入查询条件`,
        });
      },
      // 工具栏重置按钮事件
      onSubmitReset: () => {
        let { tableFormLine, appList, findMyList } = state;
        console.log("onSubmitReset!", tableFormLine);
        // toolbarFormRefs.value.resetFields();
        tableFormLine = {
          appClass: "",
          appName: "",
          appAdmin: "",
          category: "",
        };
        // 模拟清空
        appList = [];
        findMyList();
      },
      // 模拟接口数据，初始化表格
      initTestList: () => {
        return new Array(10).fill("").map((v, i) => {
          v = state.tempSource[0];
          if (i == 1) {
            v.status = 2;
          }
          v.appkey = "apk000" + i;
          return {
            ...v,
            id: i + 1,
          };
        });
      },
      // 查询
      findMyList: () => {
        let { tableLoading } = state;
        tableLoading = true;
        appSearch().then((res) => {
          if (res.data && res.data.length === 0) {
            // state.appList = state.initTestList();
            tableLoading = false;
          } else {
            state.appList = res.data;
            tableLoading = false;
          }
        });
      },
      // 按钮操作
      process: (key, item) => {
        console.log(key, item);
        if (item && item.appkey) {
          if (key == "approval") {
            // 应用审批
            appApproval(item.appkey).then((res) => {
              state.findMyList();
            });
          }
          if (key == "online") {
            // 申请启用
            appEnable(item.appkey).then((res) => {
              state.findMyList();
            });
          }
          if (key == "offline") {
            // 应用停用
            appDisable(item.appkey).then((res) => {
              state.findMyList();
            });
          }
        }
      },
      // 动态获取table高度
      getHeight: () => {
        state.tableHeight = window.innerHeight - toolbarRef.value.offsetHeight-180
      },
    });
    // 应用管理 --类别Select配置项
    const getSelectAppType = async (type) => {
      try {
        let res = await setAllByType(type);
        return res.data.map((item) => ({
          // 后端test接口type重复,此处用id区别
          value: item.id,
          label: item.name,
          id: item.id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    onBeforeMount(async () => {
      state.toolCategoryOptions = await getSelectAppType(setTypes.app);
      state.getHeight()

    });
    onMounted(() => {
      state.findMyList();
      window.addEventListener("resize", state.getHeight);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", state.getHeight);
    });
    const refData = toRefs(state);

    return {
      toolbarFormRefs,
      toolbarRef,
      fstatus: function(status) {
        return status == 0 ? "已提交" : status == 1 ? "已上线" : "已停用";
      },
      ...refData,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/mixin.scss";
@import "@/styles/button.scss";
@import "@/styles/element-ui.scss";
// modal
.body-wrap {
  height: 100%;
  @include flex(column, center);
  &::v-deep(.el-icon-question) {
    font-size: 65px;
    color: #ee3b40;
    margin-top: -60px;
  }
  &::v-deep(.el-icon-warning) {
    font-size: 65px;
    color: #ee3b40;
    margin-top: -60px;
  }
  p {
    font-size: 18px;
    font-family: SourceRegular;
    font-weight: 400;
    color: #595959;
    line-height: 23px;
    margin: 23px auto 13px;
  }
  span {
    font-size: 14px;
    font-family: SourceRegular;
    font-weight: 400;
    color: #9e9e9e;
    line-height: 23px;
  }
}
// 新增应用按钮
// .app-add-btn {
//   width: 167px;
//   height: 58px;
//   background: #ffffff;
//   box-shadow: 1px 4px 13px 0px rgba(220, 220, 220, 0.45);
//   border-radius: 4px;
//   cursor: pointer;
//   border: none;
//   outline: none;
//   font-weight: 400;
//   color: #227fff;
//   font-family: SourceRegular;
//   transition: all 0.2s linear;
//   &:hover {
//     background-color: #227fff;
//     color: #fff;
//   }
// }
.row-header {
  margin-bottom: 20px;
}
// tooltip 工具栏
.tooltip-wrap {
  width: 100%;
  height: 63px;
  background: #ffffff;
  // display: flex;
  align-items: center;
  padding: 36px 0 36px 36px;
  background-color: #fff;
  // @media only screen and (min-width: 960px) and (max-width: 1400px) {
  //   height: 170px;
  // }
  &::v-deep(.el-form-item) {
    margin-right: 30px;
  }

  // 工具栏label字体
  &::v-deep(.el-form-item__label) {
    font-size: 18px;
    font-family: SourceRegular;
    font-weight: 400;
    color: #262626;
    // line-height: 50px;
  }
  &::v-deep(.el-form-item__content) {
    // 工具栏input框
    .app-name,
    .app-admin,
    .app-class {
      width: 217px;
      height: 50px;
      border-color: #dfe3e8;
      .el-input__inner {
        height: 100%;
        width: 100%;
        &::placeholder {
          font-size: 16px;
          font-family: SourceNormal;
          font-weight: 400;
          color: #c9cbcf;
        }
      }
    }
    // 工具栏下拉框
    .app-category {
      width: 190px;
      height: 50px;
      // 下拉框
      .select-trigger {
        height: 100%;
        width: 100%;
        background: #ffffff;
        .el-input {
          height: 100%;
          width: 100%;
          .el-input__inner {
            height: 100%;
            width: 100%;
            // &::placeholder {
            //   font-family: SourceRegular;
            //   font-weight: 400;
            //   font-size: 18px;
            //   color: #3d3f41;
            // }
          }
          // 下拉icon
          .el-select__caret {
            color: #333;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .app-btn-group {
    margin-left: 44px;
    // 按钮组间距
    &::v-deep(.el-form-item__content) {
      line-height: 0;
    }
    // 查询重置按钮组
    .tooltip-btn {
      width: 100px;
      height: 50px;
      font-family: SourceNormal;
      font-weight: 400;
      font-size: 18px;
      &::v-deep(.el-form-item__content) {
        line-height: 50px;
      }
      &.search {
        background: #227fff;
        color: #ffffff;
      }
      &.reset {
        background: #fff;
        color: #7d7d7d;
        &:hover {
          color: #227fff;
        }
      }
    }
  }
}
// table 去掉表格单元格边框
.app-table {
  border: none;
  background: #f8f8f8;
  &::v-deep(td) {
    // border: none;
    th.is-leaf {
      border: none;
    }
  }
  .table-nodata {
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    img {
      width: 412px;
      height: 235px;
    }
  }
}
// table colum 应用名称
.colum-name {
  height: 100%;
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  span {
    display: flex;
    algin-items: center;
  }
}
// 操作栏按钮
.app-table {
  .app-edit-btn,
  .app-del-btn {
    width: 81px;
    height: 37px;
    background: #ffffff;
    border: 1px solid #3d8fff;
    border-radius: 4px;
    color: #3d8fff;
    text-align: center;
    padding: 0;
    min-height: 37px;
    line-height: 0;
    font-size: 16px;
    font-family: SourceRegular;
    font-weight: 400;
    transition: all 0.2s linear;
    &:hover {
      color: #fff;
      background-color: #3d8fff;
    }
  }
}
// 去除滚动条样式
::v-deep(.el-table--scrollable-y) ::-webkit-scrollbar {
  // display: none;
  // background-color: red;
}
// 表格最外边框
::v-deep(.el-table--border),
::v-deep(.el-table--group) {
  border: none;
}
::v-deep(.conf-table::before) {
  width: 0;
}
.app-table {
  // 头部边框
  ::v-deep(thead) {
    tr {
      height: 80px;
      background-color: #f8f8f8;
      font-weight: 500;
      font-family: SourceRegular;
      font-weight: 400;
      color: #888c92;
      font-size: 18px;
      th.is-leaf {
        border: 1px solid #ebeef5;
        border-right: none;
        border: none;
        background-color: #f8f8f8;
      }
      th:nth-last-of-type(2) {
        /* border-right: 1px solid #EBEEF5; */
      }
      .el-table--border::after,
      .el-table--group::after {
        width: 0;
      }
    }
  }
  ::v-deep(.el-table__fixed-right::before),
  ::v-deep(.el-table__fixed::before) {
    width: 0;
  }
  // 表格有滚动时表格头边框
  ::v-deep(.el-table--border th.gutter:last-of-type) {
    border: 1px solid #ebeef5;
    border-left: none;
  }
  ::v-deep(.el-table__body) {
    height: 100%;
    // 表格内容区域tr
    tr {
      width: 1617px;
      height: 110px;
      background: #ffffff;
      border-radius: 4px;
      font-size: 18px;
      font-family: SourceRegular;
      font-weight: 400;
      color: #58616d;
    }
  }
}

// // 去除滚动条样式
// ::v-deep.el-table--scrollable-y ::-webkit-scrollbar {
//   // display: none;
//   // background-color: red;
// }
// // 表格最外边框
// ::v-deep .el-table--border,
// ::v-deep .el-table--group {
//   border: none;
// }
// // 头部边框
// .app-table ::v-deep thead tr {
//   height: 100px;
//   background-color: #f8f8f8;
//   font-weight: 500;
//   font-family: SourceRegular;
//   font-weight: 400;
//   color: #888c92;
//   font-size: 18px;
// }
// .app-table ::v-deep thead tr th.is-leaf {
//   /* border: 1px solid #EBEEF5;
//   border-right: none; */
//   border: none;
//   background-color: #f8f8f8;
// }
// .app-table ::v-deep thead tr th:nth-last-of-type(2) {
//   /* border-right: 1px solid #EBEEF5; */
// }
// // 表格最外层边框-底部边框
// ::v-deep .el-table--border::after,
// ::v-deep .el-table--group::after {
//   width: 0;
// }
// ::v-deep .app-table::before {
//   width: 0;
// }
// .app-table ::v-deep .el-table__fixed-right::before,
// ::v-deep .el-table__fixed::before {
//   width: 0;
// }
// // 表格有滚动时表格头边框
// ::v-deep .el-table--border th.gutter:last-of-type {
//   border: 1px solid #ebeef5;
//   border-left: none;
// }
// .app-table ::v-deep .el-table__body {
//   height: 100%;
// }
// // 表格内容区域tr
// .app-table ::v-deep .el-table__body tr {
//   width: 1617px;
//   height: 110px;
//   background: #ffffff;
//   border-radius: 4px;
//   font-size: 18px;
//   font-family: SourceRegular;
//   font-weight: 400;
//   color: #58616d;
// }

// 气泡提示
.el-popover .el-row {
  line-height: 30px;
  margin: 3px;
}

.el-popover .el-row .el-tag {
  width: 80px;
  margin-right: 3px;
}

.el-popover .el-row .tooltip {
  width: 260px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -icab-text-overflow: ellipsis;
  -khtml-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
</style>
